/* tslint:disable:max-line-length */
import { FuseNavigationItem } from "@fuse/components/navigation";

export const defaultNavigation: FuseNavigationItem[] = [
  // DashBoard
  {
    id: "dashboard",
    title: "Dashboard",
    type: "basic",
    icon: "heroicons_outline:chart-pie",
    link: "/status",
  },

  // Assests
  // {
  //   id: "assets",
  //   title: "Assets",
  //   type: "basic",
  //   icon: "heroicons_outline:collection",
  //   link: "/assets",
  // },

  // Create QR
  // {
  //     id   : 'create-qr',
  //     title: 'Create-QR',
  //     type : 'basic',
  //     icon : 'heroicons_outline:view-grid-add',
  //     link : '/create-qr'
  // },

  // campaign
  // {
  //     id   : 'campaign',
  //     title: 'Campaign',
  //     type : 'basic',
  //     icon : 'heroicons_outline:view-grid-add',
  //     link : '/campaign'
  // },

  // Manage QR

  {id: "campaign",
  title: "Campaign",
  type: "collapsable", // Change type to "group" for nesting
  icon: "heroicons_outline:view-grid-add",
  children: [
   
  //  Media Campaign
    {
      id: "manage-qr",
      title: "TVC Campaign",
      type: "basic",
      icon: "heroicons_outline:qrcode",
      link: "/campaign",
    },

    // Print Campaign
    {
      id: "print-campaign",
      title: "Print Campaign",
      type: "basic",
      icon: "heroicons_outline:qrcode",
      link: "/printcampaign",
    },

    // Flex Campaign
    // {
    //   id: "flex-campaign",
    //   title: "Flex Campaign",
    //   type: "basic",
    //   icon: "heroicons_outline:qrcode",
    //   link: "/flexcampaign",
    // },
    
    // QR Campaign
    {
      id: "qr-campaign",
      title: "QR Generator",
      type: "basic",
      icon: "heroicons_outline:qrcode",
      link: "/qrcampaign",
    },

    // Multiple
    // {
    //   id: "multipleqr",
    //   title: "Multiple QR",
    //   type: "basic",
    //   icon: "heroicons_outline:qrcode",
    //   link: "/multipleqr",
    // },
  ],},

  // {
  //   id: "manage-qr",
  //   title: "Media Campaign",
  //   type: "basic",
  //   icon: "heroicons_outline:qrcode",
  //   link: "/campaign",
  // },

  // Printed Media Campaign
  // {
  //   id: "print-campaign",
  //   title: "Print Campaign",
  //   type: "basic",
  //   icon: "heroicons_outline:qrcode",
  //   link: "/printcampaign",
  // },


  // Flex Campaign
  // {
  //   id: "flex-campaign",
  //   title: "Flex Campaign",
  //   type: "basic",
  //   icon: "heroicons_outline:qrcode",
  //   link: "/flexcampaign",
  // },

  // Users
  {
    id: "users",
    title: "Users",
    type: "basic",
    icon: "heroicons_outline:users",
    link: "/users",
  },

  // Settings
  {
    id: "settings",
    title: "Settings",
    type: "basic",
    icon: "heroicons_outline:cog",
    link: "/settings",
  },

  // Plans and Billing
  {
    id: "plans",
    title: "Plans & Billings",
    type: "basic",
    icon: "heroicons_outline:currency-dollar",
    link: "/plans",
  },
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
/*export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'status',
        title: 'Status',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/status'
    }
];*/
