import { Route } from "@angular/router";
import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";
import { InitialDataResolver } from "app/app.resolvers";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: "", pathMatch: "full", redirectTo: "status" },

  // Redirect signed-in user to the '/example'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: "signed-in-redirect", pathMatch: "full", redirectTo: "status" },

  // Auth routes for guests
  {
    path: "",
    canMatch: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "confirmation-required",
        loadChildren: () =>
          import(
            "app/modules/auth/confirmation-required/confirmation-required.module"
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: "forgot-password",
        loadChildren: () =>
          import(
            "app/modules/auth/forgot-password/forgot-password.module"
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: "reset-password",
        loadChildren: () =>
          import("app/modules/auth/reset-password/reset-password.module").then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: "sign-in",
        loadChildren: () =>
          import("app/modules/auth/sign-in/sign-in.module").then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: "sign-up",
        loadChildren: () =>
          import("app/modules/auth/sign-up/sign-up.module").then(
            (m) => m.AuthSignUpModule
          ),
      },
      {
        path: "campaign/:campaignid/landingpage/:landingid/asset/:assetid",
        loadChildren: () =>
          import("app/modules/admin/landingpage/landingpage.module").then(
            (m) => m.LandingpageModule
          ),
      },
      {
        path: "campaign/:campaignid/landingpage/:landingid",
        loadChildren: () =>
          import("app/modules/admin/qr-campaign-landing/qr-campaign-landing.module").then(
            (m) => m.QrCampaignLandingModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: "",
    canMatch: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-out",
        loadChildren: () =>
          import("app/modules/auth/sign-out/sign-out.module").then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: "unlock-session",
        loadChildren: () =>
          import("app/modules/auth/unlock-session/unlock-session.module").then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Landing routes
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("app/modules/landing/home/home.module").then(
            (m) => m.LandingHomeModule
          ),
      },
    ],
  },

  // Admin routes
  {
    path: "",
    canMatch: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: "assets",
        loadChildren: () =>
          import("app/modules/admin/assets/assets.module").then(
            (m) => m.AssetsModule
          ),
      },
      {
        path: "campaign",
        loadChildren: () =>
          import("app/modules/admin/manage-qr/manage-qr.module").then(
            (m) => m.ManageQrModule
          ),
      },
      {
        path: "printcampaign",
        loadChildren: () =>
          import("app/modules/admin/print-campaign/print-campaign.module").then(
            (m) => m.PrintCampaignModule
          ),
      },
      {
        path:"flexcampaign",
        loadChildren : () => 
          import("app/modules/admin/flex-campaign/flex-campaign.module").then(
            (m) => m.FlexCampaignModule
          )
        ,
      },
      {
        path:"qrcampaign",
        loadChildren : () => 
          import("app/modules/admin/qr-campaign/qr-campaign.module").then(
            (m) => m.QrCampaignModule
          )
        ,
      },
      {
        path:"multipleqr",
        loadChildren : () => 
          import("app/modules/admin/multipleqr/multipleqr.module").then(
            (m) => m.MultipleqrModule
          )
        ,
      },

      {
        path: "settings",
        loadChildren: () =>
          import("app/modules/admin/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: "status",
        loadChildren: () =>
          import("app/modules/admin/status/status.module").then(
            (m) => m?.StatusModule
          ),
      },
      {
        path: "users",
        loadChildren: () =>
          import("app/modules/admin/users/users.module").then(
            (m) => m.UsersModule
          ),
      },
      {
        path: "create-qr",
        loadChildren: () =>
          import("app/modules/admin/create-qr/create-qr.module").then(
            (m) => m.CreateQrModule
          ),
      },
      {
        path: "plans",
        loadChildren: () =>
          import("app/modules/plans-billing/plans-billing.module").then(
            (m) => m?.PlansBillingModule
          ),
      },
    ],
  },
];
