// import { Injectable } from '@angular/core';
// import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { merge } from 'lodash-es';
// import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
// import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';

// @Injectable()
// export class FuseConfirmationService
// {
//     private _defaultConfig: FuseConfirmationConfig = {
//         title      : 'Confirm action',
//         message    : 'Are you sure you want to confirm this action?',
//         icon       : {
//             show : true,
//             name : 'feather:info',
//             color: 'primary'
//         },
//         actions    : {
//             confirm: {
//                 show : true,
//                 label: 'Confirm',
//                 color: 'primary'
//             },
//             cancel : {
//                 show : true,
//                 label: 'Cancel',
//                 color: 'warn'
//             }
//         },
//         dismissible: false
//     };

//     /**
//      * Constructor
//      */
//     constructor(
//         private _matDialog: MatDialog
//     )
//     {
//     }

//     // -----------------------------------------------------------------------------------------------------
//     // @ Public methods
//     // -----------------------------------------------------------------------------------------------------

//     open(config): MatDialogRef<FuseConfirmationDialogComponent>
//     {
//         // Merge the user config with the default config
//         const userConfig = merge({}, this._defaultConfig, config);
//         this._defaultConfig.title = config.title
//         this._defaultConfig.message = config.message
//         this._defaultConfig.actions.confirm.label = config.label_yes
//         this._defaultConfig.actions.cancel.label = config.label_no

//         // Open the dialog
//         return this._matDialog.open(FuseConfirmationDialogComponent, {
//             autoFocus   : false,
//             disableClose: !userConfig.dismissible,
//             data        : userConfig,
//             panelClass  : 'fuse-confirmation-dialog-panel'
//         });
//     }
// }


import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { merge } from 'lodash-es';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';
import { Clipboard } from '@angular/cdk/clipboard';


@Injectable()
export class FuseConfirmationService {
  message : any ;
  private _defaultConfig: FuseConfirmationConfig = {
    title: 'Confirm action',
    message: 'Are you sure you want to confirm this action?',
    icon: {
      show: true,
      name: 'feather:info',
      color: 'primary',
    },
    actions: {
      confirm: {
        show: true,
        label: 'Confirm',
        color: 'primary',
      },
      cancel: {
        show: true,
        label: 'Cancel',
        color: 'warn',
      },
    },
    dismissible: false,
  };

  /**
   * Constructor
   */
  constructor(private _matDialog: MatDialog, private clipboard: Clipboard) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  open(config): MatDialogRef<FuseConfirmationDialogComponent> {
    // Merge the user config with the default config
    const userConfig = merge({}, this._defaultConfig, config);
    this._defaultConfig.title = config.title;
    this._defaultConfig.message = config.message;
    this._defaultConfig.actions.confirm.label = config.label_yes;
    this._defaultConfig.actions.cancel.label = config.label_no;

    // Open the dialog
    return this._matDialog.open(FuseConfirmationDialogComponent, {
      autoFocus: false,
      disableClose: !userConfig.dismissible,
      data: userConfig,
      panelClass: 'fuse-confirmation-dialog-panel',
    });
  }

  openWithOneButton(title: string, message: string, buttonText: string): void {
    this.message = message;
    const confirmation = this.open({
      title: title,
      message: message,
      
      actions: {
        confirm: {
          show: true,
          label: buttonText,
          color: 'primary',
        },
        cancel: {
          show: true, 
          label: 'Close'
        },
      },
    });

    confirmation.afterClosed().subscribe((result) => {
      if (result === 'confirmed') {
        this.clipboard.copy(this.message);
        console.log('Text copied to clipboard:', this.message);



        // Handle confirmation button press here
      }
    });
  }
}

