import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TableComponent } from "./table/table.component";
import { DynamicFormComponent } from "./forms/dynamic-form/dynamic-form.component";
import { DynamicControlComponent } from "./forms/dynamic-control/dynamic-control.component";
import { MaterialModule } from "../material.module";
import { STShimmerComponent } from "./s-t-shimmer/s-t-shimmer.component";
import { CustomCalendarComponent } from "./forms/custom-calendar/custom-calendar.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { SShimmerComponent } from "./s-shimmer/s-shimmer.component";
import { ModalComponent } from "./modal.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { AppFilterPipeModule } from "../_filters/app.filter-pipe.module";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
// import {QComponent} from '../q/q.component';
import { NgxQrcodeStylingModule } from "ngx-qrcode-styling";
// import {RComponent} from '../r/r.component';
import { FuseFullscreenModule } from "@fuse/components/fullscreen";
import { FuseLoadingBarModule } from "@fuse/components/loading-bar";
import { FuseNavigationModule } from "@fuse/components/navigation";

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    ModalComponent,
    TableComponent,
    SShimmerComponent,
    DynamicFormComponent,
    DynamicControlComponent,
    CustomCalendarComponent,
    STShimmerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxMaterialTimepickerModule,
    FuseFullscreenModule,
    FuseLoadingBarModule,
    FuseNavigationModule,
    AppFilterPipeModule,
    NgxMatSelectSearchModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxQrcodeStylingModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ConfirmDialogComponent,
    TableComponent,
    ModalComponent,
    SShimmerComponent,
    DynamicFormComponent,
    DynamicControlComponent,
    CustomCalendarComponent,
    STShimmerComponent,
  ],
})
export class SharedModule {}
