
<!-- Circular Loading -->
<div class="fixed inset-0 flex items-center justify-center" *ngIf="show">
    <div class="w-20 h-20 border-t-8 border-pink-700 border-solid rounded-full animate-spin"></div>
</div>

<!-- <ng-container *ngIf="show">
    <mat-progress-bar
        [mode]="mode"
        [value]="progress"></mat-progress-bar>
</ng-container> -->