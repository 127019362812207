import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { BooleanInput } from "@angular/cdk/coercion";
import { Subject, takeUntil } from "rxjs";
import { User } from "app/core/user/user.types";
import { UserService } from "app/core/user/user.service";
import { AuthenticationService } from "app/_services/authentication.service";

@Component({
  selector: "user",
  templateUrl: "./user.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "user",
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;
  user: any = {};
  role: any = "";
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isAuthenticated = true;
  currentUser: User | undefined;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private authenticationService: AuthenticationService
  ) {
    this.user = this.authenticationService.currentUser;
    console.log("user", this.user);
    this.isAuthenticated = false;
    this.currentUser = undefined;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
        this.user = user;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status: string): void {
    // Return if user is not available
    if (!this.user) {
      return;
    }

    // Update the user
    this._userService
      .update({
        ...this.user,
        status,
      })
      .subscribe();
  }

  /**
   * Sign out
   */
  signOut(): void {
    this.authenticationService.logout();
    // this._router.navigate(['/sign-out']);
    // this.baseService.doRequest('/api/logout', 'get').subscribe((re: any) => {
    //     this.isAuthenticated = false;
    //     this.currentUser = undefined;
    //     localStorage.clear();
    //     this._router.navigate(['/sign-out']);
    // }, (err: any) => {
    //     this.isAuthenticated = false;
    //     this.currentUser = undefined;
    //     localStorage.clear();
    //     window.location.href = `${err.error.url}`;
    // });
  }
  navigateTo(url: any): any {
    this._router.navigateByUrl(url).then((r) => console.log(r));
  }
}
